<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  //import date from 'date-and-time';
  //import moment from 'moment'

  import {
    axiosInstance
  } from '../../../helpers/axios';
  export default {
    components: {
      Layout,
      PageHeader,

    },
    data() {
      return {
        title: "Anasayfa",
        items: [{
            text: "Subgate"
          },
          {
            text: "Anasayfa",
            active: true
          }
        ],
        statData: [
        ],
       

      };
    },
    created() {
      var vm = this;
      vm.istatistik();

    },
    mounted() {
      

    },
    watch:{
      
    },
    computed: {
      rows() {
        return this.transactionData.length;
      },
      yetkisi()
      {
        return this.$store.getters["auth/rl"];
      }
    },
    methods: {
      async istatistik() {
        var vm = this;
        try {

          await axiosInstance.get('anasayfa/istatistik').then((response) => {

            if (response.data.error == true) {
              vm.$bvToast.toast("Istatistik verilerinde hata olustu.! ", {
                title: `Uyari !`,
                variant: "warning",
                solid: true
              });
            } else {

              if(vm.yetkisi=="basicadmin")
              {

                vm.statData = [
                  {
                    title: "Müşteriler",
                    icon: "ri-user-fill",
                    value: response.data.data[0].musteriadet,
                    subvalue: " 2.4% "
                  },
                  {
                    title: "Kullanici Sayilar (Total)",
                    icon: "ri-file-2-fill",
                    value: response.data.data[0].toplamkullanici,
                    subvalue: " 2.4% "
                  },
                ]

                

              }else{

                vm.statData = [
                  {
                  title: "Müşteriler",
                  icon: "ri-user-fill",
                  value: response.data.data[0].musteriadet,
                  subvalue: " 2.4% "
                },
                {
                  title: "Pasif Lisans",
                  icon: "ri-router-fill",
                  value: response.data.data[0].pasiflisans,
                  subvalue: " 2.4% "
                },
                {
                  title: "Aktif Lisans",
                  icon: "ri-server-fill",
                  value: response.data.data[0].aktiflisans,
                  subvalue: " 2.4% "
                },
                {
                  title: "Kullanici Sayilar (Total)",
                  icon: "ri-file-2-fill",
                  value: response.data.data[0].toplamkullanici,
                  subvalue: " 2.4% "
                },
                {
                  title: "Update",
                  icon: "ri-pages-fill",
                  value: response.data.data[0].updateadet,
                  subvalue: " 2.4% "
                },
                {
                  title: "Bayi ve Yetkililer",
                  icon: "ri-group-fill",
                  value: response.data.data[0].yetkiliadet,
                  subvalue: " 2.4% "
                }
                ];

              }

              
                
            }

          }).catch((error) => {
            vm.$bvToast.toast("Istatistik verilerinde hata olustu.! " + error, {
              title: `Uyari !`,
              variant: "warning",
              solid: true
            });
          })

        } catch (error) {
          vm.$bvToast.toast("Istatistik verilerinde hata olustu.! " + error, {
            title: `Uyari !`,
            variant: "warning",
            solid: true
          });
        }
      },
    }
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-4" v-for="(data, index) in statData" :key="index">
            
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <div class="media-body overflow-hidden">
                    <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
                    <h4 class="mb-0">{{data.value}}</h4>
                  </div>
                  <div class="text-primary">
                    <i :class="`${data.icon} font-size-24`"></i>
                  </div>
                </div>
              </div>

           
             

            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>